import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button, Slider } from '@mui/material';
import { BrowserRouter, useSearchParams } from "react-router-dom"
import axios from 'axios';

const theme = createTheme();

const MAX = 10;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];

const URL = "https://terroir-api-447871157934.us-west1.run.app/interest"
const VISIT_URL = "https://terroir-api-447871157934.us-west1.run.app/visit"

const Interest = ({ }) => {
    const [queryParameters] = useSearchParams()
    const [email, setEmail] = useState("email")
    const [emailError, setEmailError] = useState(false)
    const [yieldVal, setYieldVal] = React.useState(MIN);
    const [diseaseVal, setDiseaseVal] = React.useState(MIN);
    const [formData, setFormData] = useState({});
    const handleYieldChange = (_, newValue) => {
        setYieldVal(newValue);
    };
    const handleDiseaseChange = (_, newValue) => {
        setDiseaseVal(newValue);
    };

    useEffect(() => {
        const visitSend = async () => {
            const jsonData = {}
            jsonData["crop"] = queryParameters.get("cid")
            if (!jsonData["crop"]) {
                jsonData["crop"] = ""
            }
            jsonData["qr"] = queryParameters.get("qr")
            if (!jsonData["qr"]) {
                jsonData["qr"] = "false"
            }
            jsonData["digital"] = queryParameters.get("d")
            if (!jsonData["digital"]) {
                jsonData["digital"] = "false"
            }
            try {
                const { resp } = await axios.post(VISIT_URL, jsonData, {

                })
                console.log(resp)
            } catch (error) {
                console.error('Error:', error);
            }
        }
        visitSend()
    }, []);

    const onSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const jsonData = {}

        for (let [key, value] of data.entries()) {
            jsonData[key] = value
        }
        jsonData["yield_val"] = yieldVal
        jsonData["disease_val"] = diseaseVal
        jsonData["crop"] = queryParameters.get("cid")
        if (!jsonData["crop"]) {
            jsonData["crop"] = ""
        }
        jsonData["qr"] = queryParameters.get("qr")
        if (!jsonData["qr"]) {
            jsonData["qr"] = "false"
        }
        jsonData["digital"] = queryParameters.get("d")
        if (!jsonData["digital"]) {
            jsonData["digital"] = "false"
        }
        if (!jsonData["yield_used"]) {
            jsonData["yield_used"] = "not given"
        }
        if (!jsonData["disease_used"]) {
            jsonData["disease_used"] = "not given"
        }
        setFormData(jsonData)

        try {
            const { resp } = await axios.post(URL, jsonData, {

            })
            console.log(resp)
        } catch (error) {
            console.log("error sending data to backend")
            console.log(error)
        }


        console.log(jsonData)
    }
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <AgricultureIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Request more information
                    </Typography>
                    <Typography>{queryParameters.get("test")}</Typography>
                    <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography id="yield_used">How many planted acres do you have?</Typography>
                                <TextField
                                    autoComplete="acreage"
                                    name="acreage"
                                    fullWidth
                                    id="acreage"
                                    label="Acreage (acres)"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <Typography id="yield_used">Do you pay for yield estimation?</Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="yield_used"
                                        name="yield_used"
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography id="input-slider" gutterBottom>
                                    How important is accurate yield estimation?
                                </Typography>
                                <Slider
                                    marks={marks}
                                    step={1}
                                    value={yieldVal}
                                    valueLabelDisplay="auto"
                                    min={MIN}
                                    max={MAX}
                                    onChange={handleYieldChange}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                        variant="body2"
                                        onClick={() => setYieldVal(MIN)}
                                        sx={{ cursor: 'pointer', color: "#555555", fontStyle: 'italic' }}
                                    >
                                        not important
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        onClick={() => setYieldVal(MAX)}
                                        sx={{ cursor: 'pointer', color: "#555555", fontStyle: 'italic' }}
                                    >
                                        extremely important
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <Typography id="disease_used">Do you pay for disease mapping?</Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="disease_used"
                                        name="disease_used"
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography id="input-slider" gutterBottom>
                                    How important is disease mapping?
                                </Typography>
                                <Slider
                                    marks={marks}
                                    step={1}
                                    value={diseaseVal}
                                    valueLabelDisplay="auto"
                                    min={MIN}
                                    max={MAX}
                                    onChange={handleDiseaseChange}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                        variant="body2"
                                        onClick={() => setDiseaseVal(MIN)}
                                        sx={{ cursor: 'pointer', color: "#555555", fontStyle: 'italic' }}
                                    >
                                        not important
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        onClick={() => setDiseaseVal(MAX)}
                                        sx={{ cursor: 'pointer', color: "#555555", fontStyle: 'italic' }}
                                    >
                                        extremely important
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="painpoints"
                                    name="painpoints"
                                    label="Biggest challenges in your business"
                                    multiline
                                    fullWidth
                                    rows={10}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="first_name"
                                    fullWidth
                                    id="first_name"
                                    label="First Name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    label="Last Name"
                                    name="last_name"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    error={emailError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="company"
                                    label="Company"
                                    name="company"
                                    autoComplete="company"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3 }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

export default Interest